import React from 'react';
import PropTypes from 'prop-types';

function Accordion({ data }) {
  return (
    <div {...{ className: 'wrapper' }}>
      <ul {...{ className: 'accordion-list' }}>
        {data.map((data, key) => {
          return (
            <li {...{ className: 'accordion-list__item', key }}>
              <AccordionItem {...data} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

class AccordionItem extends React.Component {
  state = {
    opened: false
  };

  render() {
    const {
      props: { paragraph, title },
      state: { opened }
    } = this;

    return (
      <div
        {...{
          className: `accordion-item, ${opened && 'accordion-item--opened'}`,
          onClick: () => {
            this.setState({ opened: !opened });
          }
        }}
      >
        <div {...{ className: 'accordion-item__line' }}>
          <div {...{ className: 'accordion-item__title' }}>{title}</div>
          <span {...{ className: 'accordion-item__icon' }} />
        </div>
        <div {...{ className: 'accordion-item__inner' }}>
          <div {...{ className: 'accordion-item__content' }}>
            <p {...{ className: 'accordion-item__paragraph' }}>{paragraph}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Accordion;

Accordion.propTypes = {
    data: PropTypes.array
};
