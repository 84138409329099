import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import HeaderWaves from '../components/header-waves';
import CtaFooter from '../components/cta-footer';
import Accordion from '../components/accordion';
import { Link } from 'gatsby';

const faqCommon = [
  {
    title: 'Warum sollte ich gerade jetzt einen Onlineshop erstellen?',
    paragraph:
      'Je früher, desto besser. Durch die aktuelle Lage werden die Kunden gezwungen ihre Einkäufe online zu erledigen. Das betrifft auch Kunden die bislang lieber im Laden eingekauft haben. Es ist davon auszugehen, dass diese Veränderung im Konsum auch nach dieser Phase weiterbestehen wird. Die ohnehin schon schwierige Situation der Läden verschärft sich dadurch. Schaffen Sie sich deshalb zusätzliche Sicherheit, mit einem professionellen Onlineshop.',
  },
  {
    title: 'Wie muss ich vorgehen, um Daten in meinen Onlineshop zu laden?',
    paragraph:
      'Nutzen Sie dazu unsere Schritt-für-Schritt-Anleitung, die wir Ihnen zusammen mit Ihrem Sofortshop zukommen lassen. Wir stellen Ihnen eine gut verständliche Anleitung zu Ihrem Shop bereit.',
  },
  {
    title: 'Ich habe wenig Web-Erfahrung. Gibt es Hilfen? ',
    paragraph:
      'Ja, Hilfe zur Bedienung der Shop-Oberfläche finden Sie in unserer mitgelieferten Anleitung.',
  },
  {
    title: 'Wie erfahren die Kunden von meinem Shop?',
    paragraph:
      'Wir erstellen Ihren Onlineshop und machen ihn im Internet zugänglich. Dadurch ist der neue Shop allerdings bei Ihren Kunden noch nicht bekannt, für die Bewerbung der neuen Internetpräsenz sind jedoch Sie zuständig.',
  },
  {
    title: 'Gibt es bereits ein Referenzprojekt im Internet zu sehen?',
    paragraph:
      'Ein Beispiel für unseren Shop finden Sie unter www.pluginfactory.ch. Die Besonderheit hier ist, dass keine physischen Waren, sondern Lizenzen verkauft werden, dadurch erscheint Ihnen der Shop eventuell etwas anders als die klassischen Onlineshop. Ihr Sofortshop ist jedoch auf den Verkauf & Versand physischer Waren ausgelegt. ',
  },

  {
    title: 'Kann ich die Angebote auf die Schweiz beschränken?',
    paragraph:
      'Ja, obwohl der Shop international erreichbar ist, können Sie den Versand/Verkauf nach Ihren Vorstellungen einrichten. In unserem Standardpaket «All-In-One» ist der Versand nur für Schweizer Empfängeradressen konfiguriert, wir richten Ihnen auf Anfrage aber gerne weitere Länder als mögliche Rechnungs-/Empfangsadressen ein. ',
  },
];

const faqServices = [
  {
    title: 'Welcher Support ist neben der Anleitung noch im Angebot enthalten?',
    paragraph:
      ' Damit Sie optimal mit Ihrem Shop starten können, erhalten Sie von uns ab der abgeschlossenen installation und während unserer Geschäftszeiten eine Woche kostenlosen Support per E-Mail (fair use)."',
  },

  {
    title: 'Liefern Sie nur Software zum Installieren?',
    paragraph:
      'Sie müssen nichts auf Ihrem Computer installieren. Um Ihren Sofortshop betreiben zu können, benötigen Sie lediglich einen aktuellen Internetbrowser (Microsoft Edge, Safari, Firefox, Chrome o.ä) und eine Internetverbindung. Ihr Shop läuft auf einem Server im Internet , den Sie von Ihrem Computer erreichen und per Login bearbeiten können. Das Vorgehen dazu beschreibt Ihnen unsere Anleitung, die Sie mit Ihrer Bestellung von uns erhalten. Zudem unterstützen wir Sie in der ersten Woche mit kostenlosem Support per E-Mail (fair use).',
  },

  {
    title: 'Laden Sie meine Produktlisten ins Internet oder übernehme das ich?',
    paragraph:
      'Die Erfassung der Produkte in Ihrem Shop übernehmen Sie, wir erstellen für Sie das ganze Drumherum, wie die Bedienungsoberfläche, die Zahlungsanbindung, Sicherheit, Erreichbarkeit und eine Anleitung für Ihren Shop. So können Sie sich ganz auf Ihre Produkte konzentrieren.',
  },

  {
    title: 'Was beinhaltet das Paket «All-In-One»?',
    paragraph:
      'Die einzelnen Funktionen unseres Shopsystems haben wir Ihnen auf der Seite Funktionen zusammengestellt. In unserem Paket «All-In-One» erhalten Sie neben den beschriebenen Funktionen zusätzlich die Zahlungsoption ‘Kreditkarte’, so dass Ihre Kunden in Ihrem Shop bequem per Kreditkarte bezahlen können (alternativ zur Zahlungsoption ‘Rechnung’) und ebenfalls enthalten sind die Hostingkosten und Updates im ersten Jahr. Die Hostingkosten ab dem zweiten Jahr betragen monatlich ab CHF 20.- (Standardhosting).',
  },

  {
    title: 'Wie sieht der Bestell-Ablauf aus?',
    paragraph: `Nach dem Absenden des Bestellformulars erhalten Sie von uns eine Offerte. Sie unterzeichnen diese Offerte und senden sie uns als PDF an hello@sofortshop.ch.
Wir kontaktieren Sie bei Erhalt der Offerte telefonisch und besprechen Ihr Anliegen in einem kurzen Gespräch (bis 30min). Während wir anschliessend Ihren Shop einrichten, eröffnen Sie ein Konto für Kreditkartenzahlungen und bestellen allenfalls Ihren Domainnamen. Nach zwei Tagen steht Ihr Shop für Sie und Ihre Kunden bereit.`,
  },
];

const faqRequirements = [
  {
    title:
      'Was braucht es für Aufbau und Pflege des Shops, (PC und/oder Tablet, Smartphone, eigener Datenspeicher, Browser- und Zusatzsoftware, Datenformate, schnelles Internet, etc)?',
    paragraph:
      'Zur Pflege Ihres Shops benötigen Sie lediglich ein internetfähiges Gerät (Computer/Smartphone/Tablet o.ä) mit einem aktuellen Internetbrowser (Internet Explorer, Safari, Firefox, Chrome o.ä) und einer Internetverbindung. Sie benötigen keine zusätzliche Software/Hardware.',
  },

  {
    title:
      'Muss ich etwas auf meinem PC installieren? Läuft die Software auf meinem PC oder in einer Cloud?',
    paragraph:
      'Sie müssen nichts auf Ihrem Computer installieren. Um Ihren Sofortshop betreiben zu können, benötigen Sie lediglich einen aktuellen Internetbrowser (Microsoft Edge, Safari, Firefox, Chrome o.ä) und eine Internetverbindung. Ihr Shop läuft auf einem Server im Internet , den Sie von Ihrem Computer erreichen und per Login bearbeiten können. Das Vorgehen dazu beschreibt Ihnen unsere Anleitung, die Sie mit Ihrer Bestellung von uns erhalten. Zudem unterstützen wir Sie in der ersten Woche mit kostenlosem Support per E-Mail (fair use).',
  },

  {
    title: 'Muss ich das Hosting selber organisieren?',
    paragraph:
      'Nein, das Hosting Ihres Sofortshops übernehmen wir, auch wenn Sie bereits eine Webseite haben. Ein Onlineshop stellt spezielle Anforderungen an das Hosting. Die Details dazu klären wir beim telefonischen Abklärungsgespräch. Die Hostinggebühren des ersten Jahres sind zudem im Kaufpreis enthalten. Die Hostingkosten ab dem zweiten Jahr betragen monatlich ab CHF 20.- (Standardhosting).',
  },

  {
    title: 'Kann ich meine Shop-Adresse selber benennen?',
    paragraph:
      'Ja. Wenn Sie bereits eine Website besitzen, wird Ihr Shop unter einer Subdomain Ihrer Webseite erreichbar gemacht (zum Beispiel shop.ihre-webseite.ch). Alternativ können Sie auch eine eigene Internetadresse (zum Beispiel www.mein-sofortshop.ch verwenden, sofern diese nicht schon vergeben ist (zum Beispiel wenn Sie noch keine Website besitzen). Wir klären Sie bezüglich der Möglichkeiten gerne im initialen Telefongespräch nach Bestellung auf. Oder kontaktieren Sie uns im Vorfeld über unsere Kontaktseite.',
  },

  {
    title:
      'Benötige ich eine bereits bestehende, eigene Website für einen Onlineshop?',
    paragraph:
      'Nein, Sie benötigen keine Website um unsere Dienstleistung nutzen zu können. Sollten Sie aber bereits eine Website besitzen, binden wir den Shop als Subdomain (zum Beispiel shop.ihre-webseite.ch) an Ihre bestehende Adresse an.',
  },
];

const faqProduct = [
  {
    title: 'Kann ich den Shop nur deutsch oder mehrsprachig führen?',
    paragraph:
      'Mit unserem Produkt ‘Mehrsprachig’ können Sie Ihren Onlineshop in bis zu drei verschiedenen Sprachen betreiben. Das «All-In-One»-Paket ist hingegen auf den Betrieb in einer Sprache ausgelegt.',
  },

  {
    title: 'In welchen Sprachen kann ich meinen Shop betreiben?',
    paragraph:
      'Der Sofortshop kann theoretisch in jeder Sprache betrieben werden, da Sie die Produkte selber erfassen und benennen können. Eine vollständige Übersetzung des Systems (Bezeichnungen auf den Buttons, Formulare, etc.) ist jedoch nur auf Deutsch, Französisch und Englisch vorhanden. Die Datenschutzerklärung liefern wir Ihnen nur auf Deutsch (und auf Englisch in der Sofortshop-Version «Mehrsprachig»).',
  },
];

const faqExtension = [
  {
    title: 'Welche Erweiterungsmöglichkeiten habe ich?',
    paragraph:
      'Hinter dem Produkt Sofortshop steht die Berner Agentur «Novalab». Wir realisieren seit 2006 für unsere Kunden Webseiten, Webapplikationen und Onlineshops. Es stehen Ihnen somit jegliche Möglichkeiten offen, um Ihren Shop auszubauen. Wir können Ihnen von der Konzeption über ein individuelles Design bis hin zur Programmierung von Spezialfunktionen alles anbieten. Nehmen Sie für spezifische Anforderungen gerne mit uns Kontakt auf.',
  },
];

const faqPricing = [
  {
    title:
      'Ist eine Teilzahlung für Sofortshop möglich? Kann ich vor Ende der Teilzahlung aussteigen?',
    paragraph:
      'Zu unserem «All-In-One»-Paket bieten wir Ihnen alternativ das Zahlungsmodell «Teilzahlung» an. Während einer Laufzeit von 12 Monaten können Sie Ihren Shop in 12 Raten à je CHF 180.- abbezahlen (Total CHF 2’160), danach fallen nur noch die jährlichen Betriebskosten an. Alle Infos zu unseren Produktvarianten finden Sie auf unserer Seite Preis',
  },
];

function Faq() {
  return (
    <Layout>
      <SEO
        title="Häufig gestellte Fragen"
        description="Ihre Fragen zum schnelle erstellten Onlineshop kurz und bündig beantwortet."
      />

      <HeaderWaves />

      <section className="bg-white py-8 pt-10 p-6">
        <div className="container max-w-5xl mx-auto m-8 text-gray-800">
          <h1 className="text-3xl md:text-4xl">Häufig gestellte Fragen</h1>
          <p className="text-gray-800 text-base mb-12 mx-auto">
            Die häufigsten Fragen bezüglich unseres Shop-Angebotes finden Sie
            hier. Falls Sie weitere Fragen haben,{' '}
            <Link to="/kontakt/" className="text-blue-500 hover:underline">
              kontaktieren Sie uns
            </Link>
            , wir helfen Ihnen gerne weiter.
          </p>

          <h2 className="w-full text-3xl font-bold leading-tight mb-6 md:text-center text-gray-800 mt-12">
            Allgemeines
          </h2>
          <Accordion data={faqCommon} />

          <h2 className="w-full text-3xl font-bold leading-tight mb-6 md:text-center text-gray-800 mt-12">
            Dienstleistungen von Sofortshop.ch
          </h2>
          <Accordion data={faqServices} />

          <h2 className="w-full text-3xl font-bold leading-tight mb-6 md:text-center text-gray-800 mt-12">
            Voraussetzungen kundenseitig / Installation Onlineshop
          </h2>
          <Accordion data={faqRequirements} />

          <h2 className="w-full text-3xl font-bold leading-tight mb-6 md:text-center text-gray-800 mt-12">
            Produkterfassung
          </h2>
          <Accordion data={faqProduct} />

          <h2 className="w-full text-3xl font-bold leading-tight mb-6 md:text-center text-gray-800 mt-12">
            Erweiterungen
          </h2>
          <Accordion data={faqExtension} />

          <h2 className="w-full text-3xl font-bold leading-tight mb-6 md:text-center text-gray-800 mt-12">
            Preise & Bezahlung
          </h2>
          <Accordion data={faqPricing} />

          <p className="text-gray-800 text-base mb-12 mx-auto mt-12">
            Ihre Frage wurde hier nicht beantwortet?{' '}
            <Link to="/kontakt/" className="text-blue-500 hover:underline">
              Kontaktieren Sie uns
            </Link>
            .
          </p>
        </div>
      </section>

      <CtaFooter />
    </Layout>
  );
}

export default Faq;
